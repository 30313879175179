export const Lang = {
    en: {
        //header
        menuen:'EN',
        menuar:'AR',
        menuHome:'HOME',
        menuAbout:'ABOUT US',
        submenuAbout:'ABOUT US',
        submenuAbtmsgCeo:'Message from COO',
        submentAbtTeams:'Team Structure',
        submenutAbtFinan:'Financials',
        compName:' THE NEW INDIA ASSURANCE COMPANY LIMITED',
        prdAbtBtn:'About Us',
        prdProposalBtn:'Proposal Form',
        prdClaimBtn:'Claim Form',
        submenutAbtTender:'Tender',
        menuProduct:'OUR PRODUCTS',
        testinmonital:'TESTIMONIAL',
        submenutPrdprivate:'PRIVATE',
        submenutPrdcommercial:'COMMERCIAL',
        submenutPrdindustrial:'INDUSTRIAL',
        submenutPrdlibility:'LIABILITY',
        submenutPrdOthers:'OTHERS',
        menuOffice:'OUR OFFICE',
        submenuOff:'OUR OFFICE',
        submenuofGcc:'GCC LOCATION',
        submenuOfWwl:'WORLD WIDE LOCATIONS',
        submenuOfourBranch:'OUR BRANCHES',
        menuNews:'NEWS & EVENT',
        menuGallery:'GALLERY',
        menuFaq:'FAQ',
        faqdtls:'Frequently asked questions',
        menuContact:'CONTACT',
        btnBuyonline:'Buy Online',
        btnLogin:'Login',
        btnClaim:'Claim',
        whtclitest:'What Client Says About Us',
        latneweveTitle:'Latest News & Event',
         //header

         //sliderTxt
         sliInsTxt:'Insurance to protect you better',
         sliBtnlearn:'Learn More',
         slidBtnContx:'Contact Us',
         //sliderTxt 

         //HomeOurProduct
         headHomeOPrd:'OUR PRODUCTS',
         homePrdMins:'Marine Insurance',
         homePrdMinsDtls:' All Risks and Limited Risk Covers for exports and Imports by sea air and land.',
         homePrjins:'Project Insurance',
         homePrjinsDtls:' Contractors’ All Risks Policy and Erection All Risks Policy for civil and mechanical contractors and all ...',
         homeGenins:'General Insurance',
         homeGeninsDtls:'Legal liability of insured towards damage to the third party in respect of accidental death/bodily injury...',
         homePropins:'Property Insurance',
         homePropinsDtls:' For Factories, Warehouses, Business Establishments and contents therein against physical loss or damage...',
         homeFpins:'Fire And Perils Policy',
         homeFpinsDtls:'For Factories, Warehouses, Offices, Residences and contents therein against physical loss or damage...',
         homeBusins:'Business Insurance',
         homeBusinsDtls:'Legal liability covering the acts of Omission of Professional like, Brokers, Doctors, Lawyers...',
        
         //HomeOurProduct

         //HomeAboutUS
         homeSecAbttitle:'ABOUT US',
         homeSecAbtsubhead:'We are a professional and creative company and we offer you a trusty insurance on your veicle.',
         homeSecAbtdesc:'New India Assurance Co Ltd is a multinational general insurance Government of India Public Sector Undertaking company operating in 23 Countries and Head Quartered at Mumbai in India The Global business stands at US$ 4.6 Billion completed in the last financial year 2021-2022.',
         homeSecAbtdques:'Do you have any question?',
        //HomeAboutUs

        //Homewhychoose
        homeWhyChos:'WHY CHOOSE US',
        homeWCdoyouhave:'Do you have any question?',
        homeWCdoyouhaveDtls:'We help you discover any protection inclusions that are ideal for you.',
        homeWC247:'24/7 Awesome Support',
        homeWC247Dtls:'We help you discover any protection inclusions that are ideal for you.',
        homeWCsavemon:'Save Money',
        homeWCsavemonDtls:'We help you discover any protection inclusions that are ideal for you.',
        homeWCflexplan:'Flexible Plan',
        homeWCflexplanDtls:'We help you discover any protection inclusions that are ideal for you.',
        homeWCTcom:'Trusted Company',
        homeWCTcomDtls:'We help you discover any protection inclusions that are ideal for you.',
        homeWCSafeInve:'Safe Investment',
        homeWCSafeInveDtls:'We help you discover any protection inclusions that are ideal for you.',
        //HomeWhychoose

         //footer
        headAbtUs:'ABOUT US',
        ftabtTxt:'New India Assurance Co Ltd is a multinational general insurance Government of India Public Sector Undertaking company operating in 23 Countries and Head Quartered',
        headComp:'COMPANY',
        headQuiclLink:'QUICK LINK',
        headNews:'NEWS LETTER',
        ftsubTxt:'Subscribe to our newsletter',
        ftSubPlacho:'Subscribe with us',
        ftsubBtn:'Subscribe',
        ourProduct:'Our Product',
        ourProductcom:'Commercial',
        ourProductprv:'Private',
         ourProductind:'Industrial',
         ourProductlib:'Liability',
         ourProductoth:'Other',
         readmor:'Read More',
         needText:'Need Someone to Take Care Your Future',
         equipText: 'When you re equipped to grow, we realize it is now no longer continually clean to discover the proper markets for your enterprise in different elements of the world.',
         appoint:'Make Appointment',
         btnsms:'Send Message',
         custS:'99.9% Customer satisfy based on 750+ reviews.',
         ouoffice:'Our Office',
         gccloc:'GCC Location',
         galelry:'Gallery',
         conLoc:'Location',
         quicCon:'Quick Contact',
         phone:'Phone',
         fax:'Fax',
         frmName:'Your Name *',
         frmEmail:'Your Email *',
         frmSub:'Your Subject *',
         frmCno:'Your Contact No *',
         frmMes:'Your Message *',
         //footer
    },
    ar:{
        //header
        frmName:'اسمك *',
         frmEmail:'بريدك الإلكتروني *',
         frmSub:'موضوعك *',
         frmCno:'رقم التواصل الخاص بك *',
         frmMes:'رسالتك *',
        phone:'هاتف',
        fax:'فاكس',
        quicCon:'الاتصال السريع',
        conLoc:'موقع',
          galelry:'المعرض',
        gccloc:'موقع مجلس التعاون الخليجي',
         ouoffice:'مكتبنا',
        custS:'99.9% رضا العملاء بناءً على أكثر من 750 تقييمًا.',
        btnsms:'إرسال رسالة',
        appoint:'حدد موعدا',
        equipText: 'عندما تكون مستعدًا للنمو، ندرك أنه لم يعد من السهل دائمًا العثور على الأسواق المناسبة لشركتك في أجزاء مختلفة من العالم.',

        needText:'هل تحتاج إلى شخص يهتم بمستقبلك؟',
        readmor:'اقرأ المزيد',
      
        ourProductoth:'آخر',
        ourProductlib:'مسئولية قانونية',
        ourProductprv:'خاص',
        ourProductcom:'تجاري',
         ourProduct:'منتجنا',
        compName:' شركة التأمين الهندية الجديدة المحدودة',
        menuen:'EN',
        menuar:'العربية',
        menuHome:'الصفحة الرئيسية',
        menuAbout:'معلومات عنا',
        submenuAbout:'معلومات عنا',
        submenuAbtmsgCeo:'رسالة رئيس العمليات',
        submentAbtTeams:'',
        submenutAbtFinan:'التقارير المالية',
        submenutAbtTender:'ليّن',
        menuProduct:'منتجاتنا',
        testinmonital:'شهادة',
        prdAbtBtn:'معلومات عنا',
        prdProposalBtn:'نموذج الاقتراح',
        prdClaimBtn:'نموذج المطالبة',
        whtclitest:'ماذا يقول العملاء عنا',
        latneweveTitle:'أحدث الأخبار والأحداث',
        submenutPrdprivate:'خصوصي',
        submenutPrdcommercial:'تجاري',
        submenutPrdindustrial:'صناعي',
        submenutPrdlibility:'المسؤولية',
        faqdtls:'الأسئلة الشائعة',
        submenutPrdOthers:'أخرى',
        menuOffice:'مكتبنا',
        submenuofGcc:'مكاتبنا في دول مجلس التعاون',
        submenuOfWwl:'موقع عالمي',
        submenuOfourBranch:'',
        menuNews:'أخبار و أحداث',
        menuGallery:'المعرض',
        menuFaq:'اسئلة متكررة',
        menuContact:'اتصال',
        btnBuyonline:'شراء عبر الإنترنت',
        btnLogin:'تسجيل الدخول',
        btnClaim:'مطالبة',
         //header

           //HomeOurProduct
           headHomeOPrd:'منتجاتنا',
           homePrdMins:'التأمين البحري',
           homePrdMinsDtls:'تغطية جميع المخاطر والمخاطر المحدودة للصادرات والواردات عن طريق البحر والجو والبر.',
           homePrjins:'تأمين المشروع',
           homePrjinsDtls:'سياسة جميع مخاطر المقاولين وسياسة جميع مخاطر التشييد للمقاولين المدنيين والميكانيكيين وجميع ...',
           homeGenins:'تأمين عام',
           homeGeninsDtls:'المسؤولية القانونية للمؤمن له تجاه الضرر الذي يلحق بالطرف الثالث فيما يتعلق بالوفاة العرضية / الإصابة الجسدية...',
           homePropins:'تأمين الملكية',
           homePropinsDtls:'للمصانع والمستودعات والمؤسسات التجارية ومحتوياتها ضد الخسارة أو الضرر المادي...',
           homeFpins:'سياسة الحرائق والمخاطر',
           homeFpinsDtls:'للمصانع والمستودعات والمكاتب والمساكن ومحتوياتها ضد الخسائر أو الأضرار المادية...',
           homeBusins:'تأمين عمل',
           homeBusinsDtls:'المسؤولية القانونية التي تغطي أعمال إغفال المهنيين مثل الوسطاء والأطباء والمحامين ...',
          
           //HomeOurProduct

          //HomeAboutUS
         homeSecAbttitle:'معلومات عنا',
         homeSecAbtsubhead:'نحن شركة محترفة ومبتكرة ونقدم لك تأمينًا مضمونًا على سيارتك.',
         homeSecAbtdesc:'شركة New India Assurance Co Ltd هي شركة تأمين عام متعددة الجنسيات تابعة لحكومة الهند والقطاع العام تعمل في 23 دولة ويقع مقرها الرئيسي في مومباي في الهند. تبلغ قيمة الأعمال العالمية 4.6 مليار دولار أمريكي مكتملة في السنة المالية الأخيرة 2021-2022.',
         homeSecAbtdques:'هل لديك اي سؤال؟',
        //HomeAboutUs

         //Homewhychoose
         homeWhyChos:'لماذا أخترتنا',
         homeWCdoyouhave:'هل لديك اي سؤال؟',
         homeWCdoyouhaveDtls:'نحن نساعدك على اكتشاف أي إضافات حماية مثالية بالنسبة لك.',
         homeWC247:'دعم رائع على مدار 24 ساعة طوال أيام الأسبوع',
         homeWC247Dtls:'نحن نساعدك على اكتشاف أي إضافات حماية مثالية بالنسبة لك.',
         homeWCsavemon:'توفير المال',
         homeWCsavemonDtls:'نحن نساعدك على اكتشاف أي إضافات حماية مثالية بالنسبة لك.',
         homeWCflexplan:'خطة مرنة',
         homeWCflexplanDtls:'نحن نساعدك على اكتشاف أي إضافات حماية مثالية بالنسبة لك.',
         homeWCTcom:'شركة موثوقة',
         homeWCTcomDtls:'نحن نساعدك على اكتشاف أي إضافات حماية مثالية بالنسبة لك.',
         homeWCSafeInve:'الاستثمار الآمن',
         homeWCSafeInveDtls:'نحن نساعدك على اكتشاف أي إضافات حماية مثالية بالنسبة لك. ',
         //HomeWhychoose

          //sliderTxt
          sliInsTxt:'التأمين لحمايتك بشكل أفضل',
          sliBtnlearn:'يتعلم أكثر',
          slidBtnContx:'اتصل بنا',
          //sliderTxt 


         //footer
         headAbtUs:'معلومات عنا',
         ftabtTxt:'شركة نيو إنديا للتأمين المحدودة هي شركة تأمين عام متعددة الجنسيات تابعة لحكومة الهند والقطاع العام تعمل في 23 دولة ومقرها الرئيسي',
         headComp:'شركة',
         headQuiclLink:'رابط سريع',
         headNews:'رسالة إخبارية',
         ftsubTxt:'اشترك في نشرتنا الإخبارية',
         ftSubPlacho:'اشترك معنا',
         ftsubBtn:'يشترك'
          //footer

    }
}