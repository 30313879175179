import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import { Lang } from '../component/Language/Lang';
import { useSelector,useDispatch } from "react-redux";

export const Testimonial = (props) => {
    const langData=useSelector(state=>state.cart);
  return (
    <section>
    <div className="container">
        <div className="row">
        
       
                <div className="col-lg-6 mb-2-9 mb-lg-0">
                    <Carousel
                centerSlidePercentage={100 / 1}
                selectedItem={0}
                showArrows={false}
                dots={false}
            >

{props.testMonial.map(function(data,index) {
            return(
                <div className="pe-lg-1-9"  key={index}>
                    <div className="mb-1-9 title-style1">
                        <span>{Lang[props.langData.lang].testinmonital}</span>
                        <h2 className="display-22 display-sm-18 display-md-16 mb-0">
                            {Lang[props.langData.lang].whtclitest}</h2>
                    </div>
                    <div className="testimonial-03 mb-2-9 testMonial">
                        <p className="mb-0 lead">{data.attributes.text}</p>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="flex-grow-1 ms-3">
                            <h4 className="text-secondary h5">{data.attributes.user}</h4>
                          
                        </div>
                    </div>
                </div>
            )})}
                </Carousel>
            </div>
           
            
            <div className="col-lg-6">
                <div className="row">
                    <div className="col-sm-6">
                        <img src="assets/img/content/testimonial-01.jpg" className="rounded" alt="..."/>
                    </div>
                    <div className="col-sm-6 d-none d-sm-block">
                        <img src="assets/img/content/testimonial-02.jpg" className="mb-3 rounded" alt="..."/>
                        <div className="bg-primary p-1-6 border-bottom border-width-3 rounded border-secondary-color">
                            <img src="assets/img/icons/icon-14.png" className="mb-3" alt="..."/>
                            <p className="mb-0 text-white">
                             {Lang[langData.lang].custS}
                                </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}
