import React from 'react'
import { useSelector,useDispatch } from "react-redux";
import { Lang } from './Language/Lang';

export const HomeContact = () => {
    const langData=useSelector(state=>state.cart);
  return (
    <section className="position-relative z-index-9">
    <div className="bg-img section-bg" data-overlay-dark="6" style={{ backgroundImage: "url('assets/img/bg/bg-02.jpg')" }}></div>
    <div className="container position-relative z-index-9">
        <div className="row justify-content-center mb-2-3 mb-md-6">
            <div className="col-lg-9 text-center">
                <h2 className="h1 text-white mb-3 w-md-90 w-xl-80 mx-auto">{Lang[langData.lang].needText} </h2>
                <p className="font-weight-500 mb-0 text-white w-95 w-md-85 w-xl-70 mx-auto">
                {Lang[langData.lang].equipText}
                    </p>
            </div>
        </div>
        <div className="row justify-content-center">
            <div className="col-xl-11">
                <div className="contact-wrapper-box">
                    <div className="row">
                        <div className="col-lg-6 pe-lg-0">
                            <div className="bg-img h-100 w-100 cover-background contact-image text-center" 
                            style={{ backgroundImage: "url('assets/img/bg/bg-08.jpg')" }}>
                                <div className="position-absolute bottom-5 px-1-9">
                                    <i className="ti-receipt display-20 icon bg-primary mb-3"></i>
                                    <h3 className="text-white mb-3">{Lang[langData.lang].appoint}</h3>
                                    <p className="mb-0 text-white"></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 ps-lg-0">
                            <div className="contact-form-area">
                                <form className="quform" action="#" method="post" >
                                    <div className="quform-elements">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="quform-element form-group">
                                                    <label htmlFor="name">{Lang[langData.lang].frmName}<span className="quform-required">*</span></label>
                                                    <div className="quform-input">
                                                        <input className="form-control" id="name" type="text" name="name" placeholder={Lang[langData.lang].frmName} />
                                                    </div>
                                                </div>
                                            </div>
                                           <div className="col-md-6">
                                                <div className="quform-element form-group">
                                                    <label htmlFor="email">{Lang[langData.lang].frmEmail} <span className="quform-required">*</span></label>
                                                    <div className="quform-input">
                                                        <input className="form-control" id="email" type="text" name="email" placeholder={Lang[langData.lang].frmEmail} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="quform-element form-group">
                                                    <label htmlFor="subject">{Lang[langData.lang].frmSub} <span className="quform-required">*</span></label>
                                                    <div className="quform-input">
                                                        <input className="form-control" id="subject" type="text" name="subject" placeholder={Lang[langData.lang].frmSub} />
                                                    </div>
                                                </div>
                                            </div>
                                           <div className="col-md-6">
                                                <div className="quform-element form-group">
                                                    <label htmlFor="phone">{Lang[langData.lang].frmCno}</label>
                                                    <div className="quform-input">
                                                        <input className="form-control" id="phone" type="text" name="phone"
                                                         placeholder={Lang[langData.lang].frmCno}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="quform-element form-group">
                                                    <label htmlFor="message">{Lang[langData.lang].frmMes} <span className="quform-required">*</span></label>
                                                    <div className="quform-input">
                                                        <textarea className="form-control h-auto" id="message" name="message" rows="3" 
                                                        placeholder={Lang[langData.lang].frmMes}></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                           
                                            <div className="col-md-12">
                                                <div className="quform-submit-inner">
                                                    <button className="butn-style3 secondary" type="button"><span>{Lang[langData.lang].btnsms}</span></button>
                                                </div>
                                                <div className="quform-loading-wrap text-start"><span className="quform-loading"></span></div>
                                            </div>
                                          </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

  )
}
