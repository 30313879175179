import React ,{useEffect,useState}from 'react';
import { officeGCCLocationPage ,officeGCCLocationPagear} from '../../service/apiService';
import {Tabs, Tab} from 'react-bootstrap-tabs';
import { useSelector,useDispatch } from "react-redux";
import { Lang } from '../../component/Language/Lang';

export const GccLocation = () => {
  const langData=useSelector(state=>state.cart);
  const [gccData,setgccData]=useState([]);

  const [gccDataLoc,setgccDataLoc]=useState([]);
  useEffect(() => {
    window.scrollTo({
      top: 100,
      behavior: 'smooth',
    })
    getGccData(); 
  },[]);


  

  const getGccData=async()=>{
    const newArr = [];
    const gccData=langData.lang==='ar'? await officeGCCLocationPagear(): await officeGCCLocationPage();
    console.log('dfkdfjdk',gccData.data.data);
    setgccData(gccData.data.data);
    setgccDataLoc(gccData.data.data);
  }


 

  return (
    <section>
    <div className="container"> 
                <div className="section-heading text-center mb-2-9 mb-lg-6 wow fadeIn" data-wow-delay="100ms">
                    <span>{Lang[langData.lang].gccloc}</span>
                    <h2 className="h1 mb-0">{Lang[langData.lang].gccloc}</h2>
                </div> 
                {gccData && gccData.map(function (data, index) {
                
            return (
            <div className="col-lg-12 wow fadeIn table-responsive gccTab" data-wow-delay="400ms" key={index}>
                   <Tabs onSelect={(index, label) => console.log(label + ' selected')}>
                 
                  {data.attributes && data.attributes.Location.map(function (item2, index) {
               return(
                <Tab label={item2.LocationTitle} style={{cursor:'pointer'}}>
                  {item2.Location && item2.Location.map(function (item3, index) {
                    return(
                      <div>
                      <table border="0" width="100%" cellspacing="1" cellpadding="5" bgcolor="ffffff" className='table mb-0'>
                  <tbody><tr><td className="text_01" align="left" valign="top" bgcolor="#FFFFFF" width="29%">
                    <p><strong>{item3.InchargeTitle}</strong></p><p><strong>{item3.Name}</strong>
                    <br/>{item3.Designation}</p></td>
                    <td className="text_01" align="left" valign="top" bgcolor="#FFFFFF" width="36%">
                      <p><strong>{item3.LocationTitle}</strong></p>
                      <div><p><strong>{item3.companyname}</strong></p></div>
                      <pre
                        dangerouslySetInnerHTML={{ __html: item3.OfficeAddress }}
                     />

<pre  
                        dangerouslySetInnerHTML={{ __html: item3.ResidenceAddress }}
                     />
                      
                        </td>
                        <td className="text_01" align="left" valign="top" bgcolor="#FFFFFF" width="35%">
                        <p><strong>{data.attributes.contactitle}</strong></p>
                      
                        <pre  
                        dangerouslySetInnerHTML={{ __html: item3.ContactAddress }}
                     />
                          </td></tr></tbody>
                  </table>
                  <div className="inner-title"><h2></h2></div>
                  </div>
                    )
                  })}
                </Tab>
                   
                    
                     
                )})}
                </Tabs>
               
            
              
              
{/**
  
  <table border="0" width="100%" cellspacing="1" cellpadding="5" bgcolor="ffffff" className='table mb-0'>
                  <tbody><tr><td className="text_01" align="left" valign="top" bgcolor="#FFFFFF" width="29%">
                    <p><strong>{item3.InchargeTitle}</strong></p><p><strong>{item3.Name}</strong>
                    <br/>{item3.Designation}</p></td>
                    <td className="text_01" align="left" valign="top" bgcolor="#FFFFFF" width="36%">
                      <p><strong>{item3.LocationTitle}</strong></p>
                      <div><p><strong>{item3.companyname}</strong></p></div>
                      <pre
                        dangerouslySetInnerHTML={{ __html: item3.OfficeAddress }}
                     />

<pre  
                        dangerouslySetInnerHTML={{ __html: item3.ResidenceAddress }}
                     />
                      
                        </td>
                        <td className="text_01" align="left" valign="top" bgcolor="#FFFFFF" width="35%">
                        <p><strong>{data.attributes.contactitle}</strong></p>
                      
                        <pre  
                        dangerouslySetInnerHTML={{ __html: item3.ContactAddress }}
                     />
                          </td></tr></tbody>
                  </table>
                  <div className="inner-title"><h2></h2></div>
                  </div>
                  
  */}


               {/*<Tabs onSelect={(index, label) => console.log(label + ' selected')}>
               {data.attributes.gcclocation && data.attributes.gcclocation.map(function (item, index) {
                
                  return(
                
                    <Tab label={item.GCCTitle} style={{cursor:'pointer'}}>
                       <table border="0" width="100%" cellspacing="1" cellpadding="5" bgcolor="ffffff" className='table mb-0'>
                  <tbody><tr><td className="text_01" align="left" valign="top" bgcolor="#FFFFFF" width="29%">
                    <p><strong>{item.InchargeTitle}</strong></p><p><strong>{item.Name}</strong>
                    <br/>{item.Designation}</p></td>
                    <td className="text_01" align="left" valign="top" bgcolor="#FFFFFF" width="36%">
                      <p><strong>{item.AddressTitle}</strong></p>
                      <div><p><strong>{item.companyname}</strong></p></div>
                      <pre
                        dangerouslySetInnerHTML={{ __html: item.OfficeAddress }}
                     />

<pre  
                        dangerouslySetInnerHTML={{ __html: item.ResidenceAddress }}
                     />
                      
                        </td>
                        <td className="text_01" align="left" valign="top" bgcolor="#FFFFFF" width="35%">
                        <p><strong>{data.attributes.contactitle}</strong></p>
                      
                        <pre  
                        dangerouslySetInnerHTML={{ __html: item.ContactAddress }}
                     />
                          </td></tr></tbody>
                  </table>

                    </Tab>
                  )})}
                </Tabs>*/}
                 
                
                {/*<table border="0" width="100%" cellspacing="1" cellpadding="5" bgcolor="ffffff" className='table mb-0'>
                  <tbody><tr><td className="text_01" align="left" valign="top" bgcolor="#FFFFFF" width="29%">
                    <p><strong>{data.attributes.InchargeTitle}</strong></p><p><strong>{data.attributes.Name}</strong>
                    <br/>{data.attributes.Designation}</p></td>
                    <td className="text_01" align="left" valign="top" bgcolor="#FFFFFF" width="36%">
                      <p><strong>{data.attributes.AddressTitle}</strong></p>
                      <div><p><strong>{data.attributes.companyname}</strong></p></div>
                      <div
                        dangerouslySetInnerHTML={{ __html: data.attributes.OfficeAddress }}
                     />

                     <div
                        dangerouslySetInnerHTML={{ __html: data.attributes.ResidenceAddress }}
                     />
                      
                        </td>
                        <td className="text_01" align="left" valign="top" bgcolor="#FFFFFF" width="35%">
                        <div
                        dangerouslySetInnerHTML={{ __html: data.attributes.ContactAddress }}
                     />
                          </td></tr></tbody>
                  </table>*/}
                </div>
            )})}
              
 </div>
 </section>
  )
}
