import React ,{useState,useEffect}from 'react';
import { financialAnnualRating,financialAnnualRatingar } from '../../service/apiService';
import { useSelector,useDispatch } from "react-redux";
import { Lang } from '../../component/Language/Lang';

export const FinancialRating = () => {
  const langData=useSelector(state=>state.cart);
  
  const [financialRating,setfinancialRating]=useState([]);
  const [ratingTable,setratingTable]=useState([]);
  useEffect(() => {
      getfinancialRating();
     
    },[]);

    const getfinancialRating=async()=>{
      const getFinancialrating=langData.lang==='ar'? await financialAnnualRatingar():await financialAnnualRating();
      console.log(getFinancialrating.data);
      setfinancialRating(getFinancialrating.data.data[0].attributes && getFinancialrating.data.data[0].attributes);
     // setratingTable(getFinancialrating.data.data[0].attributes && getFinancialrating.data.data[0].attributes.FinancialRatingTable);

    } 
  return (
    <section>
    <div className="container">
        <div className="section-heading text-center mb-2-9 mb-lg-6 wow fadeIn" data-wow-delay="100ms">
            <span>{financialRating.title}</span>
            <h2 className="h1 mb-0">{financialRating.title}</h2>
            
        </div>
        <p className="mb-1-9   text-secondary lh34 ">
        {financialRating.Description}
       </p>

       <div className="row about-style mb-2-9 mb-lg-6">
                <div className="col-lg-12 wow fadeIn table-responsive" data-wow-delay="400ms">
                
                <div className="col-lg-12 wow fadeIn table-responsive fratingTable"  dangerouslySetInnerHTML={{ __html: financialRating.financialrating }}/>
               {/* <table 
                className='table mb-0'>
<tbody>
<tr>
<td></td>
<td></td>
<td></td>
<td></td>
<td colspan="2"><strong>(Rs. in Crores)</strong></td>
<td></td>
</tr>
<tr>
<td width="10%">Year</td>
<td width="14%">Gross Premium<br/>
(in India)</td>
<td width="16%">Gross Premium<br/>
(Outside India)</td>
<td width="15%">Net Premium (Global)</td>
<td width="14%">Net Profit (Global)</td>
<td width="16%">Total Assets (Global)</td>
<td width="15%">Net Worth (Global)</td>
</tr>
{ratingTable.map(function(data,index) {
   return(
<tr key={index}>
<td>{data.year}</td>
<td>{data.GrossPremium}</td>
<td>{data.GrossPremiumOutsideIndia}</td>
<td>{data.NetPremiumGlobal}</td>
<td>{data.NetProfitGlobal}</td> 
<td>{data.TotalAsset}</td>
<td>{data.NetWorth}</td>
</tr>
   )
  })}



</tbody>
</table>*/}
                  </div>
  </div>
        </div>
        </section>
  )
}
