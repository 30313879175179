import React ,{useEffect,useState}from 'react';
import { officeWwideLocPage, officeWwideLocPagear } from '../../service/apiService';
import { config } from '../../config/rest';
import { useSelector,useDispatch } from "react-redux";
import { Lang } from '../../component/Language/Lang';

export const WorldWideWeb = () => {
  const langData=useSelector(state=>state.cart);
  const [wwlData,setwwlData]=useState([]);
  useEffect(() => {
    window.scrollTo({
      top: 100,
      behavior: 'smooth',
    })
    getwwlData(); 
  },[]);

  const getwwlData=async()=>{
    const wwlData=langData.lang==='ar'?await officeWwideLocPage() :await officeWwideLocPage();
    
    setwwlData(wwlData.data.data);
  }
  
  return (
    <section>
    <div className="container">
                <div className="section-heading text-center mb-2-9 mb-lg-6 wow fadeIn" data-wow-delay="100ms">
                    <span>{Lang[langData.lang].slidBtnContx}</span>
                    <h2 className="h1 mb-0">{Lang[langData.lang].slidBtnContx}</h2>
                </div>
                <div className="col-lg-12 wow fadeIn table-responsive" data-wow-delay="400ms">
                {wwlData && wwlData.map(function (data, index) {
            return (
                
                 <div className="row" key={index}>
                 
                 <div className="col-2 col-md-2"></div>
                                    <div className="col-4 col-md-4">
                                        <div className="image-hover card-style1">
                                          {data.attributes.Image.data  && 
                                            <img src={config.IMG_URL+data.attributes.Image.data.attributes.url} alt="..." /> 
                                          }
                                            </div>
                                    </div>
                                    <div className="col-4 col-md-4 text-center">
                                      <div className="card-body pb-1-9 px-1-9  ">
                                      <div dangerouslySetInnerHTML={{ __html:data.attributes.Address}} >
                                       
                                      </div>
                                      </div>
                                    
                                    </div>
          
                                   
                                </div>
                                  )})}
                            </div>
                
      </div>
</section>
  )
}
