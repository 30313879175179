import { axiosInstance } from "../config/axiosInstance";


export const listHomeSlider = () => axiosInstance.get("/home-sliders?populate=*&locale=en");
export const listHomeProduct = () => axiosInstance.get("/home-products?populate=*&locale=en");
export const listHomeAbout = () => axiosInstance.get("/homeabouts?populate=*&locale=en");
export const listHomeWhychooseUs = () => axiosInstance.get("/homewhy-chooseuses?populate=*&locale=en");
export const listHomeTestimonial = () => axiosInstance.get("/home-testimonials?populate=*&locale=en");
export const listHomeNews = () => axiosInstance.get("/homenews?populate=*&locale=en?sort[0]=date");
export const listHomecontacts = () => axiosInstance.get("/contacts?populate=*&locale=en");



export const abtPage = () => axiosInstance.get("/aboutuses?populate=*&locale=en");
export const ceomessagePage = () => axiosInstance.get("/abt-ceo-messages?populate=*&locale=en");
export const tenderPage = () => axiosInstance.get("/tenders?populate=*&locale=en");

export const productPrivatePage = () => axiosInstance.get("/product-privates?populate=*&locale=en");
export const productcomPage = () => axiosInstance.get("/product-commercials?populate=*&locale=en");
export const productindPage = () => axiosInstance.get("/product-industrials?populate=*&locale=en");
export const produlibilityPage = () => axiosInstance.get("/product-liabilities?populate=*&locale=en");
export const produothersPage = () => axiosInstance.get("/product-others?populate=*&locale=en");


export const listNewsEventData = () => axiosInstance.get("/newsevents?populate=*&locale=en");

export const faqPage = () => axiosInstance.get("/faqs?populate=*&locale=en");
export const faqCeoPage = () => axiosInstance.get("/faq-ceo-details?populate=*&locale=en");
export const faqContactPage = () => axiosInstance.get("/faqcontact-details?populate=*&locale=en");



export const officeOurOfficePage = () => axiosInstance.get("/our-offices?populate=*&locale=en");
export const officeGCCLocationPage = () => axiosInstance.get("/our-office-gcc-locations?populate[0]=Location.Location&locale=en");
export const officeWwideLocPage = () => axiosInstance.get("/our-office-world-wide-locations?populate=*&locale=en");

export const officeTeamWithImage= () => axiosInstance.get("/team-structures?populate[0]=TeamWithImage&populate[1]=TeamWithImage.image&locale=en");
export const officeTeamStructurePage = () => axiosInstance.get("/team-structures?populate=*&locale=en");//data[0].attributes.AnnualReport


export const financialAnnualReport= () => axiosInstance.get("/annual-reports?populate=*?populate[0]=AnnualReport&populate[1]=AnnualReport.file&locale=en");
export const financialAnnualRating= () => axiosInstance.get("/financial-ratings?populate=*&locale=en");
export const financialPerformance= () => axiosInstance.get("/financial-performances?populate=*&locale=en");


export const getAdvertisement = () => axiosInstance.get("/popup-messages?populate=*&locale=en");












/*arabicApi */
export const listHomeSliderar = () => axiosInstance.get("/home-sliders?populate=*&locale=ar");
export const listHomeProductar = () => axiosInstance.get("/home-products?populate=*&locale=ar");
export const listHomeAboutar = () => axiosInstance.get("/homeabouts?populate=*&locale=ar");
export const listHomeWhychooseUsar = () => axiosInstance.get("/homewhy-chooseuses?populate=*&locale=ar");
export const listHomeTestimonialar = () => axiosInstance.get("/home-testimonials?populate=*&locale=ar");
export const listHomeNewsar = () => axiosInstance.get("/homenews?populate=*&locale=ar&sort[0]=date");
export const listHomecontactsar = () => axiosInstance.get("/contacts?populate=*&locale=ar");



export const abtPagear = () => axiosInstance.get("/aboutuses?populate=*&locale=ar");
export const ceomessagePagear = () => axiosInstance.get("/abt-ceo-messages?populate=*&locale=ar");
export const tenderPagear = () => axiosInstance.get("/tenders?populate=*&locale=ar");

export const productPrivatePagear = () => axiosInstance.get("/product-privates?populate=*&locale=ar");
export const productcomPagear = () => axiosInstance.get("/product-commercials?populate=*&locale=ar");
export const productindPagear = () => axiosInstance.get("/product-industrials?populate=*&locale=ar");
export const produlibilityPagear = () => axiosInstance.get("/product-liabilities?populate=*&locale=ar");
export const produothersPagear = () => axiosInstance.get("/product-others?populate=*&locale=ar");


export const listNewsEventDataar = () => axiosInstance.get("/newsevents?populate=*&locale=ar");

export const faqPagear = () => axiosInstance.get("/faqs?populate=*&locale=ar");
export const faqCeoPagear = () => axiosInstance.get("/faq-ceo-details?populate=*&locale=ar");
export const faqContactPagear = () => axiosInstance.get("/faqcontact-details?populate=*&locale=ar");

export const officeOurOfficePagear = () => axiosInstance.get("/our-offices?populate=*&locale=ar");
export const officeGCCLocationPagear = () => axiosInstance.get("/our-office-gcc-locations?populate[0]=Location.Location&locale=ar");
export const officeWwideLocPagear = () => axiosInstance.get("/our-office-world-wide-locations?populate=*&locale=ar");

export const officeTeamWithImagear= () => axiosInstance.get("/team-structures?populate[0]=TeamWithImage&populate[1]=TeamWithImage.image&locale=ar");
export const officeTeamStructurePagear = () => axiosInstance.get("/team-structures?populate=*&locale=ar");//data[0].attributes.AnnualReport


export const financialAnnualReportar= () => axiosInstance.get("/annual-reports?populate=*?populate[0]=AnnualReport&populate[1]=AnnualReport.file&locale=ar");
export const financialAnnualRatingar= () => axiosInstance.get("/financial-ratings?populate=*&locale=ar");
export const financialPerformancear= () => axiosInstance.get("/financial-performances?populate=*&locale=ar");


export const getAdvertisementar = () => axiosInstance.get("/popup-messages?populate=*&locale=ar");
/*arabicApi */