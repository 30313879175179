import React,{useState,useEffect} from 'react'
import { Office } from './Office'
import { Marketing } from './Marketing'
import { SubAgent } from './SubAgent'
import { officeOurOfficePage ,officeOurOfficePagear} from '../../service/apiService';
import { useSelector,useDispatch } from "react-redux";
import { Lang } from '../../component/Language/Lang';

export const Content = () => {
  const langData=useSelector(state=>state.cart);
  const [ourOffice,setOurOffice]=useState([]);
  const [marketSupoort,setmarketSupoort]=useState([]);
  const [subAgent,setsubAgent]=useState([]);
  useEffect(() => {
    window.scrollTo({
      top: 100,
      behavior: 'smooth',
    })
    getourOffice();
  },[]);
  const getourOffice=async()=>{
    const office=langData.lang==='ar'? await officeOurOfficePagear() :await officeOurOfficePage();  
    console.log(office.data.data[0].attributes); 
    setOurOffice(office.data.data[0].attributes && office.data.data[0].attributes.ouroffice);
    setmarketSupoort(office.data.data[0].attributes && office.data.data[0].attributes.MarketingSupportTeam);
    setsubAgent(office.data.data[0].attributes && office.data.data[0].attributes.subagent);
  }

  return (
    <section>
    <div className="container">
                <div className="section-heading text-center mb-2-9 mb-lg-6 wow fadeIn" data-wow-delay="100ms">
                    <span>{Lang[langData.lang].ouoffice}</span>
                    <h2 className="h1 mb-0">{Lang[langData.lang].ouoffice}</h2>
                </div>
                <div>
               <Office ourOfficeData={ourOffice}/>
               <Marketing MarketingData={marketSupoort}/> 
               <SubAgent SubAgentData={subAgent}/>
                </div>
 </div>
 </section>
  )
}
